//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, computed } from '@nuxtjs/composition-api'
import useCustomerData from '~/composables/useCustomerData'
import useCustomerHistory from '~/composables/useCustomerHistory'
import useCustomerShoppingCart from '~/composables/useCustomerShoppingCart'
import useLoginInformation from '~/composables/useLoginInformation'
import useCurrentLanguage from '~/composables/useCurrentLanguage'

export default defineComponent({
  name: 'MenuBar',
  props: {
    logo: {
      type: Object,
      default: null
    },
    faqLink: {
      type: String,
      default: null
    },
    accountIcon: {
      type: Array,
      default: () => []
    },
    accountOverviewLink: {
      type: Array,
      default: () => []
    },
    myOrdersLink: {
      type: Array,
      default: () => []
    },
    dashboardLink: {
      type: Array,
      default: () => []
    },
    bookmarksLink: {
      type: Array,
      default: () => []
    },
    navigation: {
      type: Array,
      default: () => []
    }
  },
  setup () {
    const { userIsLoggedIn, universalLogout, routeToKcLogin, routeToKcRegister } = useLoginInformation()
    const { customerFullName } = useCustomerData()
    const { currentLanguage } = useCurrentLanguage()
    const { history } = useCustomerHistory()
    const { shoppingCart, shoppingCartAmount, shoppingCartLink } = useCustomerShoppingCart()

    const historyArticles = computed(() => {
      if (history.value) {
        return history.value.map((article) => {
          return {
            ...article,
            image: article.vehicleImage
          }
        })
      }
    })

    const shoppingCartArticles = computed(() => {
      if (shoppingCart.value) {
        return shoppingCart.value.map((article) => {
          return {
            ...article,
            image: article.imageUrl
          }
        })
      }
    })

    return {
      userIsLoggedIn,
      customerFullName,
      historyArticles,
      shoppingCartArticles,
      shoppingCartAmount,
      currentLanguage,
      shoppingCartLink,
      universalLogout,
      routeToKcRegister,
      routeToKcLogin
    }
  }
})
