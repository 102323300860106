//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, ref, computed, useRoute, inject } from '@nuxtjs/composition-api'
import { onClickOutside } from '@vueuse/core'

export default defineComponent({
  props: {
    label: {
      type: String,
      default: ''
    },
    icon: {
      type: Object,
      default: null
    },
    ctaLink: {
      type: Array,
      default: () => []
    },
    topicLink: {
      type: String,
      default: ''
    },
    columns: {
      type: Array,
      default: () => []
    },
    headline: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    const route = useRoute()
    const open = ref(false)
    const hover = ref(false)
    const topicRef = ref(null)
    const buttonRef = ref(null)
    const showBackdrop = inject('showBackdrop', ref(false))

    const active = computed(() => {
      const currentRawPath = route.value.path.split('?')[0].split('#')[0]
      const currentPath = currentRawPath.endsWith('/') ? currentRawPath : `${currentRawPath}/`
      const targetPath = props.topicLink.endsWith('/') ? props.topicLink : `${props.topicLink}/`

      if (currentPath === targetPath) {
        return true
      }

      if (currentPath.split('/').length > 3 && targetPath.split('/').length > 3) {
        return currentPath.startsWith(targetPath)
      }
      return false
    })

    const rows = computed(() => {
      if (!props.columns.length) {
        return 0
      }
      return Math.max(...props.columns.map(column => column.content.length))
    })

    onClickOutside(topicRef, () => {
      if (open.value) {
        open.value = false
        showBackdrop.value = false
      }
    }, { ignore: [buttonRef] })

    function toggleMenu () {
      if (rows.value > 0) {
        open.value = !open.value
        showBackdrop.value = open.value
      }
    }

    function closeMenu () {
      open.value = false
      showBackdrop.value = false
    }

    function columnWidth (width) {
      switch (width) {
        case '1-2':
          return 'w-1/2'
        case '1-3':
          return 'w-1/3'
        case '1-4':
          return 'w-1/4'
        case '1-6':
          return 'w-1/6'
        default:
          return ''
      }
    }

    return {
      topicRef,
      buttonRef,
      open,
      hover,
      active,
      toggleMenu,
      closeMenu,
      rows,
      columnWidth
    }
  }
})
