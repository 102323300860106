//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    pulse: {
      type: Boolean,
      default: true
    },
    small: {
      type: Boolean,
      default: false
    },
    defaultStyle: {
      type: Boolean,
      default: true
    }
  }
})
