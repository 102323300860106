//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, ref, computed } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    label: {
      type: String,
      default: ''
    },
    icon: {
      type: Object,
      default: null
    },
    ctaLink: {
      type: Array,
      default: () => []
    },
    topicLink: {
      type: String,
      default: ''
    },
    columns: {
      type: Array,
      default: () => []
    }
  },
  setup (props, { emit }) {
    const open = ref(false)

    const directNavigation = computed(() => !props.columns.length)

    function openOverlay () {
      if (!directNavigation.value) {
        open.value = true
      } else {
        emit('closeOverlay')
      }
    }

    return {
      open,
      openOverlay,
      directNavigation
    }
  }
})
