import { ref, onMounted, watchEffect } from '@nuxtjs/composition-api'
import useLoginInformation from '~/composables/useLoginInformation'
import useBmfApi from '~/composables/useBmfApi'
import useFaroIntegration from '~/composables/useFaroIntegration'

const history = ref(null)

const useCustomerHistory = () => {
  const { userStateIsReady } = useLoginInformation()
  const { getVehicleHistory } = useBmfApi()
  const isLoading = ref(false)
  const { faroError } = useFaroIntegration()

  async function getHistory () {
    if (history.value === null && !isLoading.value) {
      isLoading.value = true
      try {
        const response = await getVehicleHistory()
        history.value = response.data
      } catch (err) {
        history.value = []
        faroError(err, 'bmfapi-getHistory')
      } finally {
        isLoading.value = false
      }
    }
  }

  onMounted(() => {
    if (userStateIsReady.value) {
      getHistory()
    } else {
      const unwatch = watchEffect(() => {
        if (userStateIsReady.value) {
          getHistory()
          unwatch()
        }
      })
    }
  })

  return {
    history
  }
}

export default useCustomerHistory
