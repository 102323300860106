//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    open: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: 'w-36'
    },
    rightSide: {
      type: Boolean,
      default: false
    }
  }
})
