//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, ref, computed } from '@nuxtjs/composition-api'
import { onClickOutside, useWindowSize } from '@vueuse/core'
import useLoginInformation from '~/composables/useLoginInformation'
import useShopSearch from '~/composables/useShopSearch'
import useFaroIntegration from '~/composables/useFaroIntegration'

export default defineComponent({
  name: 'SearchBar',
  setup () {
    const showSelect = ref(false)
    const refSelect = ref(null)
    const target = ref(null)
    const { faroError } = useFaroIntegration()

    const { userIsLoggedIn, userIsAuthenticated } = useLoginInformation()
    const {
      searchOptions,
      sendShopSearch,
      setSearchUrl,
      matchCode,
      resetMatchCodeSearch,
      searchCategory,
      searchInput,
      getMatchcodeHints
    } = useShopSearch()
    const windowWidth = useWindowSize().width

    const showMatchCodeBox = computed(() => showSelect.value && !refSelect.value.dropdownOpen)

    function debounce (func, timeout = 300) {
      let timer
      return (...args) => {
        clearTimeout(timer)
        timer = setTimeout(() => func.apply(this, args), timeout)
      }
    }
    const processChange = debounce(() => {
      getMatchcodeHints()
    })

    async function onEnter () {
      showSelect.value = false
      if (userIsAuthenticated.value && searchInput.value) {
        const searchUrl = setSearchUrl(searchCategory.value, searchInput.value)
        try {
          await sendShopSearch(searchCategory.value, searchInput.value)
          searchInput.value = ''
          window.location.assign(searchUrl)
        } catch (err) {
          faroError(err, 'searchstore-onEnter')
        }
      }
    }

    onClickOutside(target, (event) => {
      if (event) {
        resetMatchCodeSearch()
        showSelect.value = false
      }
    })

    // Styling calculations:
    const gridWidth = computed(() => {
      const w = windowWidth.value - 16 // Full Window width without padding-x
      return w / 12
    })

    const selectBoxWidth = computed(() => {
      return Math.floor(gridWidth.value * 2) // company logo grid spans over 3 rows => so we use this space for the select box
    })

    const lgScreen = computed(() => windowWidth.value >= 1200)
    const mdScreen = computed(() => windowWidth.value >= 960 && !lgScreen.value)

    const cssVars = computed(() => (
      {
        '--select-box-width': !mdScreen.value ? '100%' : `${selectBoxWidth.value}px`,
        '--grid-width': `${Math.floor(gridWidth.value)}px`
      }
    ))

    return {
      searchCategory,
      searchInput,
      onEnter,
      showSelect,
      userIsLoggedIn,
      target,
      cssVars,
      mdScreen,
      lgScreen,
      selectBoxWidth,
      gridWidth,
      matchCode,
      processChange,
      searchOptions,
      refSelect,
      showMatchCodeBox,
      getMatchcodeHints
    }
  }
})
