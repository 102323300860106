//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api'
import useLoginInformation from '~/composables/useLoginInformation'
import useCurrentLanguage from '~/composables/useCurrentLanguage'

export default defineComponent({
  props: {
    home: {
      type: String,
      default: ''
    },
    faqLink: {
      type: String,
      default: ''
    },
    loggedOutTopics: {
      type: Array,
      default: () => []
    },
    loggedInTopics: {
      type: Array,
      default: () => []
    }
  },
  setup () {
    const { userIsLoggedIn, routeToKcLogin, routeToKcRegister } = useLoginInformation()
    const { currentLanguage } = useCurrentLanguage()

    return {
      routeToKcLogin,
      routeToKcRegister,
      currentLanguage,
      userIsLoggedIn
    }
  }
})
