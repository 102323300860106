//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, watch, ref, computed, nextTick, onMounted, onUnmounted } from '@nuxtjs/composition-api'
import { useScroll, useElementSize } from '@vueuse/core'
import useWindowScrollLock from '~/composables/useWindowScrollLock'

export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: null
    }
  },
  setup (props) {
    const menu = ref(null)
    const drawer = ref(null)
    const cta = ref(null)
    const { arrivedState } = useScroll(menu)
    const ctaDimensions = useElementSize(cta)
    const { lockWindow, unlockWindow } = useWindowScrollLock()

    let mq1, mq2

    const menuHasScrollbar = ref(false)

    watch(() => props.visible, async () => {
      await nextTick()
      if (!menu.value) {
        menuHasScrollbar.value = false
      } else {
        menuHasScrollbar.value = menu.value.scrollHeight > menu.value.clientHeight
      }
    })

    watch(() => props.visible, handleWindowLock)

    const cssVars = computed(() => {
      return {
        '--cta-height': `${ctaDimensions?.height.value}px`
      }
    })

    function handleWindowLock () {
      if (!process.client) { return }
      const refIsVisible = window.getComputedStyle(drawer.value).getPropertyValue('display') !== 'none'

      if (props.visible && refIsVisible) {
        lockWindow(drawer)
      } else {
        unlockWindow(drawer)
      }
    }

    onMounted(() => {
      mq1 = window.matchMedia('(min-width: 640px)')
      mq2 = window.matchMedia('(max-width: 960px)')
      mq1.addEventListener('change', handleWindowLock)
      mq2.addEventListener('change', handleWindowLock)
    })

    onUnmounted(() => {
      mq1.removeEventListener('change', handleWindowLock)
      mq2.removeEventListener('change', handleWindowLock)
    })

    return {
      drawer,
      menu,
      cta,
      arrivedState,
      menuHasScrollbar,
      cssVars
    }
  }
})
