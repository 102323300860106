import { render, staticRenderFns } from "./ArticleList.vue?vue&type=template&id=88a1d15a&"
import script from "./ArticleList.vue?vue&type=script&lang=js&"
export * from "./ArticleList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LazyLoader: require('/builds/reifen_gundlach/frontend/components/skeleton/LazyLoader/LazyLoader.vue').default,ArticleListItem: require('/builds/reifen_gundlach/frontend/components/atoms/ArticleListItem/ArticleListItem.vue').default,EmptyArticleList: require('/builds/reifen_gundlach/frontend/components/molecules/EmptyArticleList/EmptyArticleList.vue').default})
