import { computed } from '@nuxtjs/composition-api'
import useLoginInformation from '~/composables/useLoginInformation'
import useCustomerData from '~/composables/useCustomerData'
import useCompanyCustomerList from '~/composables/useCompanyCustomerList'

const useCustomerRole = () => {
  const { userIsLoggedIn } = useLoginInformation()
  const { customerData } = useCustomerData()
  const { companyCustomerList } = useCompanyCustomerList()

  // customer has role HAUPTNUTZER
  const isMainUser = computed(() => {
    if (!customerData.value.role) { return null }
    return customerData.value.role === 'HAUPTNUTZER' && userIsLoggedIn.value
  })

  const activeMainUserList = computed(() => {
    if (companyCustomerList.value) {
      // active MAINUSER must have state 'ACCEPTED' (not: 'INVITED' or 'DEACTIVATED')
      return companyCustomerList.value.filter(user => user?.role === 'HAUPTNUTZER' && user?.state !== 'INVITED' && user?.state !== 'DEACTIVATED')
    }
    return []
  })

  const activeUserList = computed(() => {
    if (companyCustomerList.value) {
      return companyCustomerList.value.filter(user => user?.state !== 'INVITED' && user?.state !== 'DEACTIVATED')
    }
    return []
  })

  return { isMainUser, activeMainUserList, activeUserList }
}
export default useCustomerRole
