import { render, staticRenderFns } from "./Menu.vue?vue&type=template&id=67f7c25f&scoped=true&"
import script from "./Menu.vue?vue&type=script&lang=js&"
export * from "./Menu.vue?vue&type=script&lang=js&"
import style0 from "./Menu.vue?vue&type=style&index=0&id=67f7c25f&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67f7c25f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MenuTopic: require('/builds/reifen_gundlach/frontend/components/menu/MenuTopic/MenuTopic.vue').default})
