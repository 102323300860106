import axios from 'axios'
import { reactive, ref, toRefs, watchEffect, useContext, onMounted } from '@nuxtjs/composition-api'
import useLoginInformation from '~/composables/useLoginInformation'
import useCurrentLanguage from '~/composables/useCurrentLanguage'
import useBmfApi from '~/composables/useBmfApi'
import useFaroIntegration from '~/composables/useFaroIntegration'

const state = reactive({
  latestSearchTerms: [],
  searchCategory: 'Tires',
  searchInput: ''
})

const matchCode = reactive({
  data: null,
  keys: ['width', 'ratio', 'diameter', 'season', 'speedIndex', 'manufacturer'],
  isLoading: false
})

const useShopSearch = () => {
  const { userIsAuthenticated } = useLoginInformation()
  const { $config, $t } = useContext()
  const { bmfShopUrl } = $config
  const { currentLanguage } = useCurrentLanguage()
  const { getMatchCode } = useBmfApi()
  const { faroError } = useFaroIntegration()

  const searchOptions = ref([
    { value: 'Tires', label: $t('category.tires'), matchcodeKey: 'tires' },
    { value: 'Rims', label: $t('category.rims'), matchcodeKey: 'alloy-rims' },
    { value: 'Steelrims', label: $t('category.steelrims'), matchcodeKey: 'steel-rims' },
    { value: 'StaticCompleteWheels', label: $t('category.wheels') }
  ])

  async function getLatestSearchTerms () {
    try {
      const reply = await axios.get('/proxy/searchstore/queries')
      state.latestSearchTerms = reply.data.queryList?.map((item) => {
        const category = item.category
        const searchTerm = decodeURI(item.searchTerm)
        return ({
          category,
          searchTerm,
          searchUrl: setSearchUrl(category, searchTerm)
        })
      }) ?? []
    } catch (err) {
      faroError(err, 'searchstore-getLatestSearchTerms')
    }
  }

  function setSearchUrl (category, searchTerm) {
    return encodeURI(`${bmfShopUrl}/Article/${category}?search=${searchTerm}&sprache=${currentLanguage.value}`)
  }

  function getCategoryTranslation (value) {
    return searchOptions.value.find(option => option.value === value).label
  }

  function sendShopSearch (category, searchTerm) {
    const searchJson = {
      category,
      searchTerm
    }

    if (userIsAuthenticated.value) {
      return axios.post('/proxy/searchstore/queries', searchJson)
    } else {
      return false
    }
  }

  function resetMatchCodeSearch () {
    matchCode.isLoading = false
    matchCode.data = null
  }

  async function getMatchcodeHints () {
    matchCode.isLoading = true
    const categoryKey = searchOptions.value.find(option => option.value === state.searchCategory).matchcodeKey

    if (userIsAuthenticated.value) {
      if (state.searchInput.length >= 3 && categoryKey) {
        try {
          const reply = await getMatchCode(state.searchInput, categoryKey)
          const data = {}

          matchCode.keys.forEach((key) => {
            data[`${key}`] =
              reply.data[`${key}List`]?.map(item => ({
                value: item,
                label: item.replace('|', ': '),
                match: item === reply.data[`${key}Match`]
              })
              )
          })

          matchCode.data = { ...data }
        } catch (err) {
          faroError(err, 'bmfapi-getMatchcodeHints')
        }
      } else {
        resetMatchCodeSearch()
      }
    }
    matchCode.isLoading = false
  }

  onMounted(() => {
    if (userIsAuthenticated.value) {
      getLatestSearchTerms()
    } else {
      const unwatch = watchEffect(() => {
        if (userIsAuthenticated.value) {
          getLatestSearchTerms()
          unwatch()
        }
      })
    }
  })

  return {
    ...toRefs(state),
    sendShopSearch,
    setSearchUrl,
    getMatchcodeHints,
    matchCode,
    resetMatchCodeSearch,
    searchOptions,
    getCategoryTranslation
  }
}
export default useShopSearch
