//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, ref, watch } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    value: {
      type: Boolean,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    labelAlignment: {
      type: String,
      default: 'left'
    }
  },
  setup (props) {
    const checkValue = ref(props.value)
    watch(() => props.value, () => {
      checkValue.value = props.value
    })

    return {
      checkValue
    }
  }
})
