//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    data: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ''
    }
  }
})
