//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, watch, ref } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'RgSelectDropdown',
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => []
    },
    value: {
      type: [String, Number],
      default: null
    },
    preview: {
      type: Array,
      default: () => []
    },
    dropdownSelectionIcon: {
      type: String,
      default: ''
    }
  },
  emits: ['input'],
  setup (props, { emit }) {
    const inputValue = ref(props.value)

    watch(() => props.value, () => {
      inputValue.value = props.value
    })

    function handleSelect (option) {
      emit('input', option)
    }

    return {
      inputValue,
      handleSelect
    }
  }
})
