//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    option: {
      type: Object,
      default: () => {}
    },
    active: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    }
  }
})
