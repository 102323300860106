//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, ref, computed, watch, onMounted, nextTick } from '@nuxtjs/composition-api'
import { onClickOutside } from '@vueuse/core'

export default defineComponent({
  name: 'RgSelect',
  props: {
    id: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: null
    },
    options: {
      type: Array,
      default: () => []
    },
    showLabel: {
      type: Boolean,
      default: false
    },
    dropdownSelectionIcon: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number],
      default: null
    },
    error: {
      type: Boolean,
      default: false
    },
    stretchedDropdown: {
      type: Boolean,
      default: false
    },
    preview: {
      type: Array,
      default: () => []
    },
    styleClass: {
      type: String,
      default: 'default' // searchbar
    }
  },
  emits: ['input'],
  setup (props, { emit }) {
    const target = ref(null)
    const dropdownOpen = ref(false)
    const inputValue = ref(props.value)
    const currentInputIndex = ref(0)
    const keySearch = ref('')

    watch(() => props.value, () => {
      setValues()
    })

    function scrollFct () {
      const dropdownWrapper = target.value.querySelector('.dropdown-wrapper')
      const activeOption = dropdownWrapper.querySelector('.dropdown-option.active-option')

      if (process.client && activeOption && dropdownOpen.value) {
        // get index of element in list:
        const index = props.options.map(option => option.value).indexOf(inputValue.value) || 0
        // get list of elements in preview-wrapper, if there is one (e.g. for phone field):
        const previewWrapperElements = dropdownWrapper.querySelector('.preview-wrapper')?.querySelectorAll('.dropdown-option').length || 0
        // get height of activeOption - default value: 48px
        const elementHeight = activeOption.getBoundingClientRect().height || 48
        // calculate scroll top distance + 2 to show element centered in dropdown wrapper:
        dropdownWrapper.scrollTop = elementHeight * (index + previewWrapperElements - 2)
      }
    }

    const optionLabel = computed(() => {
      const option = props.options.find(option => option.value === inputValue.value)
      return option?.shortLabel || option?.label || ''
    })

    function setValues () {
      inputValue.value = props.value
    }

    function handleKeyboardEvent (event) {
      currentInputIndex.value = props.options.findIndex((option) => {
        return option.value === inputValue.value
      })

      if (/^[a-z0-9]$/i.test(event.key)) {
        keySearch.value = `${keySearch.value}${event.key.toLowerCase()}`
        const firstMatch = props.options.find((option) => {
          return option.label.toLowerCase().startsWith(keySearch.value)
        })
        if (firstMatch) {
          inputValue.value = firstMatch.value
          scrollFct()
        } else {
          clearSearch()
        }
      } else {
        if (event.keyCode === 9) { // tab
          dropdownOpen.value = false
        } else if (event.keyCode === 40) { // down
          event.preventDefault()
          inputValue.value = props.options[(currentInputIndex.value + 1) % props.options.length].value
          scrollFct()
        } else if (event.keyCode === 38) { // up
          event.preventDefault()
          inputValue.value = props.options[(currentInputIndex.value === 0 ? props.options.length - 1 : currentInputIndex.value - 1) % props.options.length].value
          scrollFct()
        }
        clearSearch()
      }
      emit('input', inputValue.value)
    }

    function handleSelect (option) {
      dropdownOpen.value = false
      inputValue.value = option.value
      emit('input', option.value)
    }

    function toggleDropdown () {
      dropdownOpen.value = !dropdownOpen.value
      if (!dropdownOpen.value) {
        clearSearch()
        emit('blur')
      } else {
        nextTick(() => { scrollFct() })
      }
    }

    function clearSearch () {
      keySearch.value = ''
    }

    onClickOutside(target, (event) => {
      if (event && dropdownOpen.value) {
        toggleDropdown()
      }
    })

    onMounted(() => {
      setValues()
    })

    return {
      inputValue,
      handleSelect,
      toggleDropdown,
      optionLabel,
      dropdownOpen,
      target,
      handleKeyboardEvent
    }
  }
})
