//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, onMounted, nextTick, ref } from '@nuxtjs/composition-api'
import { useScroll } from '@vueuse/core'

export default defineComponent({
  props: {
    contentClasses: {
      type: [Array, String],
      default: ''
    }
  },
  setup () {
    const contentBox = ref(null)
    const { arrivedState } = useScroll(contentBox)
    const contentHasScrollbar = ref(false)

    onMounted(async () => {
      await nextTick()
      if (!contentBox.value) {
        contentHasScrollbar.value = false
      } else {
        contentHasScrollbar.value = contentBox.value.scrollHeight > contentBox.value.clientHeight
      }
    })

    return {
      contentBox,
      contentHasScrollbar,
      arrivedState
    }
  }
})
