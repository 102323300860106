import axios from 'axios'

const useSettingsStoreApi = () => {
  function setSettingStoreEntry (data) {
    return axios.put('/proxy/settingsstore/settingsstore/v1/config/entry', data)
  }

  function getSettingsStoreEntry (key) {
    return axios.get(`/proxy/settingsstore/settingsstore/v1/config/entry?key=${key}`)
  }

  function getAllSettingStoreEntries () {
    return axios.get('/proxy/settingsstore/settingsstore/v1/config')
  }

  return {
    setSettingStoreEntry,
    getSettingsStoreEntry,
    getAllSettingStoreEntries
  }
}
export default useSettingsStoreApi
