import { useContext, ref, watchEffect, onMounted } from '@nuxtjs/composition-api'
import useCurrentLanguage from '~/composables/useCurrentLanguage'
import useLoginInformation from '~/composables/useLoginInformation'
import useBmfApi from '~/composables/useBmfApi'
import useFaroIntegration from '~/composables/useFaroIntegration'
import { useNotifications } from '~/composables/useNotifications'

const shoppingCart = ref(null)
const shoppingCartAmount = ref(null)
const isLoading = ref(false)

const useCustomerShoppingCart = () => {
  const { currentLanguage } = useCurrentLanguage()
  const { userStateIsReady } = useLoginInformation()
  const { bmfShopUrl } = useContext().$config
  const { getShoppingCartEntries, getShoppingCartAmount, addShoppingCartItems } = useBmfApi()
  const { faroError } = useFaroIntegration()
  const { addNotification } = useNotifications()

  const shoppingCartLink = `${bmfShopUrl}/ShoppingCart?sprache=${currentLanguage.value}`
  const addToCartIsLoading = ref(false)

  async function getShoppingCart () {
    if (!isLoading.value) {
      isLoading.value = true
      try {
        const responseItems = await getShoppingCartEntries()
        shoppingCart.value = responseItems.data
        const responseAmount = await getShoppingCartAmount()
        shoppingCartAmount.value = responseAmount.data.shoppingCartAmount
      } catch (err) {
        shoppingCart.value = []
        faroError(err, 'bmfapi-getShoppingCart')
      } finally {
        isLoading.value = false
      }
    }
  }

  async function addToCart (id, amount) {
    addToCartIsLoading.value = true
    try {
      await addShoppingCartItems(id, amount)
      addNotification('noti-shoppingCart-success')
      getShoppingCart()
    } catch (error) {
      addNotification(`noti-shoppingCart-${error.response.data?.statusCode}`)
      faroError(error, 'bmf-api-addShoppingCartItems')
    } finally {
      addToCartIsLoading.value = false
    }
  }

  onMounted(() => {
    if (userStateIsReady.value && shoppingCartAmount.value === null) {
      getShoppingCart()
    } else {
      const unwatch = watchEffect(() => {
        if (userStateIsReady.value && shoppingCartAmount.value === null) {
          getShoppingCart()
          unwatch()
        }
      })
    }
  })

  return {
    shoppingCart,
    shoppingCartAmount,
    shoppingCartLink,
    addToCartIsLoading,
    getShoppingCart,
    addToCart
  }
}

export default useCustomerShoppingCart
