const useSearchHighlight = () => {
  function highlightSearchProperty (propertyString, searchTerm, regexFilter) {
    const regex = new RegExp(searchTerm, regexFilter)
    const propertyText = propertyString.toString()
    const tmp = document.createElement('div')
    tmp.innerText = propertyText
    const encoded = {
      value: tmp.innerHTML,
      found: false
    }

    if (!searchTerm) {
      return encoded
    }

    if (regex.test(encoded.value)) {
      encoded.found = true
      encoded.value = encoded.value.replace(regex, (foundText) => { return `<span class="search-highlight">${foundText}</span>` })
    }

    return encoded
  }

  return {
    highlightSearchProperty
  }
}

export default useSearchHighlight
