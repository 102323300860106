//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api'
import useShopSearch from '~/composables/useShopSearch'
import useFaroIntegration from '~/composables/useFaroIntegration'

export default defineComponent({
  props: {
    search: {
      type: Object,
      default: () => {}
    }
  },
  setup () {
    const { sendShopSearch, getCategoryTranslation } = useShopSearch()
    const { faroError } = useFaroIntegration()

    async function searchAndNavigate (search) {
      try {
        await sendShopSearch(search.category, search.searchTerm)
        window.location.assign(search.searchUrl)
      } catch (err) {
        faroError(err, 'searchstore-searchAndNavigate')
      }
    }
    return {
      searchAndNavigate,
      getCategoryTranslation
    }
  }
})
