//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'History',
  props: {
    articles: {
      type: Array,
      default: null
    }
  }
})
