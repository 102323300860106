import { render, staticRenderFns } from "./SearchBar.vue?vue&type=template&id=2deeec43&scoped=true&"
import script from "./SearchBar.vue?vue&type=script&lang=js&"
export * from "./SearchBar.vue?vue&type=script&lang=js&"
import style0 from "./SearchBar.vue?vue&type=style&index=0&id=2deeec43&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2deeec43",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RgSelect: require('/builds/reifen_gundlach/frontend/components/forms/RgSelect/RgSelect.vue').default,Icon: require('/builds/reifen_gundlach/frontend/components/atoms/Icon/Icon.vue').default,MatchCodeBox: require('/builds/reifen_gundlach/frontend/components/organisms/MatchCodeBox/MatchCodeBox.vue').default})
