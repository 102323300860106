import axios from 'axios'
import { useContext } from '@nuxtjs/composition-api'
import useCurrentLanguage from '~/composables/useCurrentLanguage'

const useBmfApi = () => {
  const { currentLanguage } = useCurrentLanguage()
  const { $config } = useContext()
  const { bmfLoggedOutToken } = $config

  function getVehicleHistory () {
    return axios.get(`/proxy/bmfapi/vehicle/history/${currentLanguage.value}`)
  }

  function getOrderDeadline () {
    return axios.get(`/proxy/bmfapi/order/deadline/${currentLanguage.value}`)
  }

  function addShoppingCartItems (idCode, amount) {
    return axios.post(`/proxy/bmfapi/shoppingCart/${currentLanguage.value}/add`, {
      idCode,
      amount
    })
  }

  function getShoppingCartEntries () {
    return axios.get(`/proxy/bmfapi/shoppingCart/${currentLanguage.value}`)
  }

  function getShoppingCartAmount () {
    return axios.get('/proxy/bmfapi/shoppingCart/amount')
  }

  function getBmfArticleList (ids) {
    return axios.post(`/proxy/bmfapi/article/list/${currentLanguage.value}`, ids)
  }

  function getBmfArticle (id) {
    if (!id) {
      return null
    }
    return axios.get(`/proxy/bmfapi/article/${currentLanguage.value}/${id}`)
  }

  function getLoggedOutBmfArticle (id) {
    if (!id) {
      return null
    }
    return axios.get(`/proxy/bmfapi/Article/${currentLanguage.value}/noUser/${id}`, { headers: { Authorization: bmfLoggedOutToken } })
  }

  function getLoggedOutBmfArticleList (ids) {
    if (!ids) {
      return null
    }
    return axios.post(
      `/proxy/bmfapi/Article/${currentLanguage.value}/noUser/list`,
      ids,
      { headers: { Authorization: bmfLoggedOutToken } }
    )
  }

  function getMatchCode (searchTerm, categoryKey) {
    return axios.get(`/proxy/bmfapi/MatchCode/suggestion/${currentLanguage.value}/${categoryKey}?searchText=${searchTerm}`)
  }

  return {
    getVehicleHistory,
    getOrderDeadline,
    getShoppingCartEntries,
    getShoppingCartAmount,
    getBmfArticleList,
    getBmfArticle,
    getMatchCode,
    addShoppingCartItems,
    getLoggedOutBmfArticle,
    getLoggedOutBmfArticleList
  }
}
export default useBmfApi
