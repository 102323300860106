import { render, staticRenderFns } from "./MenuTopic.vue?vue&type=template&id=e23783c8&scoped=true&"
import script from "./MenuTopic.vue?vue&type=script&lang=js&"
export * from "./MenuTopic.vue?vue&type=script&lang=js&"
import style0 from "./MenuTopic.vue?vue&type=style&index=0&id=e23783c8&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e23783c8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ImageWrapper: require('/builds/reifen_gundlach/frontend/components/atoms/ImageWrapper/ImageWrapper.vue').default,LinkItem: require('/builds/reifen_gundlach/frontend/components/atoms/LinkItem/LinkItem.vue').default,NuxtDynamic: require('/builds/reifen_gundlach/frontend/node_modules/@blokwise/dynamic/src/components/Dynamic.vue').default})
