//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'ShoppingCart',
  props: {
    articles: {
      type: Array,
      default: null
    }
  },
  setup () {
    return {}
  }
})
