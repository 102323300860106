import { onUnmounted } from '@nuxtjs/composition-api'

const lockRefs = new Set()

const useWindowScrollLock = () => {
  function lockWindow (ref) {
    lockRefs.add(ref)
    setWindowLockStatus()
  }

  function unlockWindow (ref) {
    lockRefs.delete(ref)
    setWindowLockStatus()
  }

  function clearLocks () {
    lockRefs.clear()
    setWindowLockStatus()
  }

  function setWindowLockStatus () {
    if (lockRefs.size > 0) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }

  onUnmounted(() => {
    clearLocks()
  })

  return {
    lockWindow,
    unlockWindow,
    clearLocks
  }
}

export default useWindowScrollLock
