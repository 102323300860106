var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"target",class:[{'relative': !_vm.stretchedDropdown}, (_vm.styleClass + "-layout")]},[_c('div',{staticClass:"floating-label"},[_c('label',{directives:[{name:"show",rawName:"v-show",value:(_vm.showLabel || _vm.value || (_vm.styleClass === 'searchbar')),expression:"showLabel || value || (styleClass === 'searchbar')"}],staticClass:"label",class:{
        'label--error': _vm.error,
        'label--disabled': _vm.disabled
      },attrs:{"for":_vm.id},on:{"click":_vm.toggleDropdown}},[_vm._v("\n      "+_vm._s(_vm.label)+"\n    ")])]),_vm._v(" "),_c('div',[_c('div',{on:{"keydown":_vm.handleKeyboardEvent}},[_c('button',{staticClass:"select group",class:{
          'select--open': _vm.dropdownOpen,
          'select--disabled' : _vm.disabled,
          'select--error': _vm.error
        },attrs:{"id":_vm.id,"disabled":_vm.disabled,"type":"button"},on:{"click":_vm.toggleDropdown}},[(_vm.optionLabel)?_c('span',{staticClass:"truncate selected-option-label"},[_vm._v(_vm._s(_vm.optionLabel))]):_c('span',{staticClass:"truncate",class:{'label--error': _vm.error}},[_vm._v(_vm._s(_vm.label))]),_vm._v(" "),_c('div',{staticClass:"flex items-center justify-center p-1 rounded",class:{
            'group-focus:bg-petrol group-focus:text-white group-hover:bg-petrol-200': _vm.styleClass === 'searchbar' && !_vm.disabled,
            'group-hover:bg-white/20': _vm.styleClass === 'searchbar' && _vm.disabled
          }},[_c('Icon',{staticClass:"select-dropdown-icon text-petrol",class:[{
              'select-dropdown--disabled': _vm.disabled,
              'select-dropdown--open': _vm.dropdownOpen,
              'group-focus:text-white': _vm.styleClass === 'searchbar'
            }],attrs:{"icon":"IconChevronDown"}})],1)])])]),_vm._v(" "),_c('RgSelectDropdown',{attrs:{"is-open":_vm.dropdownOpen,"options":_vm.options,"preview":_vm.preview,"dropdown-selection-icon":_vm.dropdownSelectionIcon},on:{"input":function($event){return _vm.handleSelect($event)}},model:{value:(_vm.inputValue),callback:function ($$v) {_vm.inputValue=$$v},expression:"inputValue"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }