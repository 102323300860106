import { render, staticRenderFns } from "./SettingsToggle.vue?vue&type=template&id=766cd32a&"
import script from "./SettingsToggle.vue?vue&type=script&lang=js&"
export * from "./SettingsToggle.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ToggleSelect: require('/builds/reifen_gundlach/frontend/components/atoms/ToggleSelect/ToggleSelect.vue').default})
