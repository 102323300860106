//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, computed, ref, watch } from '@nuxtjs/composition-api'
import useShopSearch from '~/composables/useShopSearch'
import useSearchHighlight from '~/composables/useSearchHighlight'
import useVisibleFade from '~/composables/useVisibleFade'

export default defineComponent({
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    const { matchCode, latestSearchTerms, searchCategory, searchInput } = useShopSearch()
    const showLatestSearchTerms = ref(true)
    const { highlightSearchProperty } = useSearchHighlight()

    const itemRef = ref(null)
    const wrapperRef = ref(null)
    const { visibleFadeRight } = useVisibleFade(itemRef, wrapperRef)

    const matchCodeHasData = computed(() => {
      if (matchCode.data) {
        const matchCodeDataEntries = matchCode.keys.reduce(
          (count, key) => count + (matchCode.data[key]?.length || 0),
          0
        )
        return matchCodeDataEntries > 0
      }
      return false
    })

    const searchTermMatches = computed(() => {
      const list = latestSearchTerms.value.filter(term => highlightSearchProperty(term.searchTerm, searchInput.value, 'i').value !== term.searchTerm)
      if (list.length) {
        return formatSearchTermList(list)
      } else if (!matchCodeHasData.value) {
        return formatSearchTermList(latestSearchTerms.value)
      }
      return []
    })

    function formatSearchTermList (searchTermList) {
      return searchTermList.map(term => ({
        ...term,
        searchTermHtml: highlightSearchProperty(term.searchTerm, searchInput.value, 'i').value
      }))
    }

    watch(() => [searchInput.value, matchCodeHasData.value], () => {
      if ((!matchCodeHasData.value || !searchInput.value) && latestSearchTerms.value.length) {
        showLatestSearchTerms.value = true
      } else if (showLatestSearchTerms.value) {
        showLatestSearchTerms.value = !!searchTermMatches.value.length
      }
    })

    return {
      matchCode,
      matchCodeHasData,
      latestSearchTerms,
      searchCategory,
      searchTermMatches,
      showLatestSearchTerms,
      searchInput,
      itemRef,
      wrapperRef,
      visibleFadeRight
    }
  }
})
