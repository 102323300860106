import { toRefs, reactive } from '@nuxtjs/composition-api'
import useUsermanagementApi from '~/composables/useUsermanagementApi'
import useLoginInformation from '~/composables/useLoginInformation'
import useCustomerData from '~/composables/useCustomerData'
import useFaroIntegration from '~/composables/useFaroIntegration'

const state = reactive({
  companyCustomerList: null,
  fetchingCompanyList: false
})

const useCompanyCustomerList = () => {
  const { customerData } = useCustomerData()
  const { getCustomersFromCompany, deactivateCustomer, activateCustomer } = useUsermanagementApi()
  const { clearDataForLoggedOutUser } = useLoginInformation()
  const { faroError } = useFaroIntegration()

  async function getCompanyCustomerList () {
    try {
      const reply = await getCustomersFromCompany()
      return reply.data.customers
    } catch (err) {
      faroError(err, 'usermanagement-getCustomersFromCompany')
      state.fetchingCompanyList = false
      return []
    }
  }

  async function getAndSortCompanyCustomerList () {
    if (!state.fetchingCompanyList) {
      state.fetchingCompanyList = true
      try {
        const customers = await getCompanyCustomerList()
        if (customers.length) {
          const myCustomer = customers.find(customer => customer.uuid === customerData.value.uuid)
          state.companyCustomerList = customers
            .sort((a, b) => a.lastName.localeCompare(b.lastName))
            .filter(customer => customer.uuid !== customerData.value.uuid)
          state.companyCustomerList.unshift(myCustomer)
          state.fetchingCompanyList = false
        }
      } catch (err) {
        faroError(err, 'usermanagement-getAndSortCompanyCustomerList')
      }
    }
  }

  async function deactivateUser (uuid) {
    try {
      await deactivateCustomer(uuid)
      getAndSortCompanyCustomerList()

      if (uuid === customerData.value.uuid) {
        clearDataForLoggedOutUser()
      }
    } catch (err) { faroError(err, 'usermanagement-deactivateUser') }
  }

  async function activateUser (uuid) {
    try {
      await activateCustomer(uuid)
      getAndSortCompanyCustomerList()
    } catch (err) { faroError(err, 'usermanagement-activateUser') }
  }

  return {
    ...toRefs(state),
    getAndSortCompanyCustomerList,
    deactivateUser,
    activateUser
  }
}
export default useCompanyCustomerList
