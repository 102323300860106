//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, ref } from '@nuxtjs/composition-api'
import { onClickOutside } from '@vueuse/core'

export default defineComponent({
  name: 'MenuIcon',
  props: {
    label: {
      type: String,
      default: ''
    },
    icon: {
      type: [String, Object],
      default: ''
    },
    link: {
      type: String,
      default: ''
    },
    indicator: {
      type: Number,
      default: 0
    }
  },
  setup () {
    const active = ref(false)
    const menuIcon = ref(null)
    const popOver = ref(null)
    const overlay = ref(null)
    const options = {
      ignore: [menuIcon, overlay]
    }

    function closeOverlay () {
      active.value = false
    }

    onClickOutside(popOver, () => {
      if (active.value) {
        active.value = false
      }
    }, options)

    return {
      active,
      menuIcon,
      popOver,
      overlay,
      closeOverlay
    }
  }
})
