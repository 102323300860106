//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'ArticleList',
  props: {
    articles: {
      type: Array,
      default: null
    },
    hasLink: {
      type: Boolean,
      default: false
    },
    emptyTitle: {
      type: String,
      default: ''
    },
    emptyDescription: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    }
  },
  setup () {
    return {}
  }
})
