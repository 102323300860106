//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'Indicator',
  props: {
    value: {
      type: Number,
      default: 0
    }
  },
  setup () {
    return {}
  }
})
