//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, useContext, computed } from '@nuxtjs/composition-api'
import useCurrentLanguage from '~/composables/useCurrentLanguage'

export default defineComponent({
  name: 'ArticleListItem',
  props: {
    article: {
      type: Object,
      default: null
    },
    hasLink: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const { $config } = useContext()
    const { bmfShopUrl } = $config
    const { currentLanguage } = useCurrentLanguage()

    const articleLink = computed(() => {
      return `${bmfShopUrl}/Artikel/Komplettraeder§Felgen-Komfortsuche/Montage/resetFilter/true/PKWIDCode/${props.article.vehicleIdCode}?sprache=${currentLanguage.value}`
    })

    return { articleLink }
  }
})
