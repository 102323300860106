import { computed, toRefs } from '@nuxtjs/composition-api'
import { useScroll, useElementBounding } from '@vueuse/core'
import { useOnScroll } from 'vue-composable'

const useVisibleFade = (itemRef, scrollRef) => {
  const { scrollLeft } = useOnScroll(scrollRef)
  const { width: itemWidth } = useElementBounding(itemRef)
  const { width: wrapperWidth } = useElementBounding(scrollRef)
  const { arrivedState } = useScroll(scrollRef)
  const { right } = toRefs(arrivedState)

  const visibleFadeRight = computed(() => itemWidth.value > wrapperWidth.value && !right.value)
  const visibleFadeLeft = computed(() => scrollLeft.value > 0)

  return {
    visibleFadeRight,
    visibleFadeLeft
  }
}

export default useVisibleFade
