//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, ref, computed } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    color: {
      type: String,
      default: 'orange'
    },
    primaryColor: {
      type: String,
      default: undefined
    },
    lineStyle: {
      type: String,
      default: 'bold'
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click'],
  setup (props) {
    const hover = ref(false)

    const underlineStyle = computed(() => {
      const generalStyles = 'absolute transition-all z-10'
      const bgStyle = props.primaryColor ? 'bg-primary-color' : `bg-${props.color}`
      if (props.lineStyle === 'bold') {
        return [generalStyles, bgStyle, 'h-1 bottom-[-2px]', (hover.value || props.active) ? 'w-[calc(100%+0.125rem)] opacity-100' : 'w-0 opacity-0']
      } else {
        return [generalStyles, bgStyle, 'h-0.5 bottom-0', (hover.value || props.active) ? 'w-full opacity-100' : 'w-0 opacity-0']
      }
    })

    const cssVars = computed(() => {
      return {
        '--primary-color': props.primaryColor
      }
    })

    return {
      underlineStyle,
      hover,
      cssVars
    }
  }
})
