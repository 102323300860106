import { onMounted, watchEffect, computed, reactive } from '@nuxtjs/composition-api'
import useCustomerRole from './useCustomerRole'
import useLoginInformation from '~/composables/useLoginInformation'
import useCustomerData from '~/composables/useCustomerData'
import useFaroIntegration from '~/composables/useFaroIntegration'
import useUsermanagementApi from '~/composables/useUsermanagementApi'
import useSettingsStoreApi from '~/composables/useSettingsStoreApi'

const actions = reactive({
  ConsumerMode: {
    value: null,
    isMainUserSetting: false
  },
  ExternalWarehousePopupSwitch: {
    value: null,
    isMainUserSetting: false
  },
  ReifenGarantie: {
    value: null,
    isMainUserSetting: false
  },
  'ebp.dashboard.wasVisited': {
    value: null,
    isMainUserSetting: false
  },
  'ebp.dashboard.order': {
    value: null,
    isMainUserSetting: false
  },
  'ebp.game.highscore': {
    value: null,
    isMainUserSetting: false
  },
  activateKPIs: {
    value: null,
    isMainUserSetting: true,
    isReadOnly: true
  },
  'ebp.visibilitySideuser.kpi': {
    value: null,
    isMainUserSetting: true
  },
  'ebp.visibilitySideuser.openInvoice': {
    value: null,
    isMainUserSetting: true
  }
})

const init = reactive({
  initialized: false
})

const useSettingsStore = (currentAction) => {
  const { userIsAuthenticated, userStateIsReady } = useLoginInformation()
  const { faroError } = useFaroIntegration()
  const { setCompanySetting } = useUsermanagementApi()
  const { getSettingsStoreEntry, setSettingStoreEntry, getAllSettingStoreEntries } = useSettingsStoreApi()
  const { isMainUser } = useCustomerRole()
  const { userInfo } = useCustomerData()

  async function getAllSettingsFromStore () {
    if (!init.initialized && userInfo.value) {
      init.initialized = true
      const response = await getAllSettingStoreEntries()
      const allCustomerEntries = response.data.config
      const companySettingEntries = userInfo.value?.company?.details?.settings || null

      Object.keys(actions).forEach((action) => {
        if (actions[action].isMainUserSetting && companySettingEntries?.[action]) {
          actions[action].value = returnActionValue(companySettingEntries[action])
        } else if (allCustomerEntries[action]) {
          actions[action].value = returnActionValue(allCustomerEntries[action])
        } else {
          actions[action].value = false
          if (!actions[action].isReadOnly) {
            setActionValue(false, action)
          }
        }
      })
    }
  }

  function returnActionValue (actionData) {
    if (typeof actionData === 'string' && actionData.toLowerCase() === 'true') {
      return true
    } else if (typeof actionData === 'string' && actionData.toLowerCase() === 'false') {
      return false
    } else {
      return actionData
    }
  }

  async function setActionValue (value, key = null) {
    const keyName = key || currentAction
    actions[keyName].value = value // at the beginning to reduce time for animation after click

    if (keyName !== null && keyName !== undefined && !actions[keyName].isReadOnly) {
      try {
        if (actions[keyName]?.isMainUserSetting && isMainUser.value) {
          await setCompanySetting(keyName, value)
          return true
        } else if (!actions[keyName]?.isMainUserSetting) {
          const data = {
            config: {
              [keyName]: value
            }
          }
          await setSettingStoreEntry(data)
          return true
        }
      } catch (err) {
        faroError(err, 'settingstore-setActionValue')
      }
    }
    return false
  }

  const invoicesVisibleForUser = computed(() => {
    if (isMainUser.value !== null && userIsAuthenticated.value) {
      return isMainUser.value || (actions['ebp.visibilitySideuser.openInvoice']?.value)
    }
    return null
  })

  onMounted(() => {
    if (userStateIsReady.value && isMainUser.value !== null && userInfo.value) {
      getAllSettingsFromStore()
    } else {
      const unwatch = watchEffect(() => {
        if (userStateIsReady.value && isMainUser.value !== null && userInfo.value) {
          getAllSettingsFromStore()
          unwatch()
        }
      })
    }
  })

  return {
    getSettingsStoreEntry,
    actions,
    init,
    setActionValue,
    invoicesVisibleForUser
  }
}
export default useSettingsStore
