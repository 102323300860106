import { render, staticRenderFns } from "./MatchCodeBox.vue?vue&type=template&id=2171a7f3&"
import script from "./MatchCodeBox.vue?vue&type=script&lang=js&"
export * from "./MatchCodeBox.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LatestSearchTermOption: require('/builds/reifen_gundlach/frontend/components/molecules/LatestSearchTermOption/LatestSearchTermOption.vue').default,LinkItem: require('/builds/reifen_gundlach/frontend/components/atoms/LinkItem/LinkItem.vue').default,LazyLoader: require('/builds/reifen_gundlach/frontend/components/skeleton/LazyLoader/LazyLoader.vue').default,MatchCodeColumn: require('/builds/reifen_gundlach/frontend/components/atoms/MatchCodeColumn/MatchCodeColumn.vue').default,ScrollGradientBox: require('/builds/reifen_gundlach/frontend/components/atoms/ScrollGradientBox/ScrollGradientBox.vue').default})
