//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, computed } from '@nuxtjs/composition-api'
import useCustomerRole from '~/composables/useCustomerRole'
import useSettingsStore from '~/composables/useSettingsStore'

export default defineComponent({
  name: 'SettingsToggle',
  props: {
    headline: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    action: {
      type: String,
      default: ''
    },
    actionText: {
      type: String,
      default: ''
    },
    negation: {
      type: Boolean,
      default: false
    },
    labelAlignment: {
      type: String,
      default: 'left'
    },
    textColor: {
      type: String,
      default: 'text-petrol'
    }
  },
  emits: ['input'],
  setup (props, { emit }) {
    const { setActionValue, actions } = useSettingsStore(props.action)
    const { isMainUser } = useCustomerRole()

    function handleChange (value) {
      setActionValue(props.negation ? !value : value)
      emit('input', value)
    }

    const showSettingForCurrentUser = computed(() => (isMainUser.value && actions[props.action]?.isMainUserSetting) || !actions[props.action]?.isMainUserSetting)

    return {
      handleChange,
      showSettingForCurrentUser,
      actions
    }
  }
})
