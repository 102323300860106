import { render, staticRenderFns } from "./MobileMenu.vue?vue&type=template&id=5b15c683&scoped=true&"
import script from "./MobileMenu.vue?vue&type=script&lang=js&"
export * from "./MobileMenu.vue?vue&type=script&lang=js&"
import style0 from "./MobileMenu.vue?vue&type=style&index=0&id=5b15c683&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b15c683",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MobileMenuTopic: require('/builds/reifen_gundlach/frontend/components/menu/MobileMenuTopic/MobileMenuTopic.vue').default,LinkItem: require('/builds/reifen_gundlach/frontend/components/atoms/LinkItem/LinkItem.vue').default,Button: require('/builds/reifen_gundlach/frontend/components/atoms/Button/Button.vue').default,SettingsToggle: require('/builds/reifen_gundlach/frontend/components/molecules/SettingsToggle/SettingsToggle.vue').default})
