import { render, staticRenderFns } from "./MenuIcon.vue?vue&type=template&id=5ba1faa6&"
import script from "./MenuIcon.vue?vue&type=script&lang=js&"
export * from "./MenuIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/builds/reifen_gundlach/frontend/components/atoms/Icon/Icon.vue').default,Indicator: require('/builds/reifen_gundlach/frontend/components/atoms/Indicator/Indicator.vue').default,ItemUnderline: require('/builds/reifen_gundlach/frontend/components/atoms/ItemUnderline/ItemUnderline.vue').default,MiniHeaderPopOver: require('/builds/reifen_gundlach/frontend/components/atoms/MiniHeaderPopOver/MiniHeaderPopOver.vue').default,DrawerOverlay: require('/builds/reifen_gundlach/frontend/components/molecules/DrawerOverlay/DrawerOverlay.vue').default})
