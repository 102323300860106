import { render, staticRenderFns } from "./MenuBar.vue?vue&type=template&id=137d35da&"
import script from "./MenuBar.vue?vue&type=script&lang=js&"
export * from "./MenuBar.vue?vue&type=script&lang=js&"
import style0 from "./MenuBar.vue?vue&type=style&index=0&id=137d35da&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ImageWrapper: require('/builds/reifen_gundlach/frontend/components/atoms/ImageWrapper/ImageWrapper.vue').default,SearchBar: require('/builds/reifen_gundlach/frontend/components/molecules/SearchBar/SearchBar.vue').default,Button: require('/builds/reifen_gundlach/frontend/components/atoms/Button/Button.vue').default,Icon: require('/builds/reifen_gundlach/frontend/components/atoms/Icon/Icon.vue').default,BgSlide: require('/builds/reifen_gundlach/frontend/components/atoms/BgSlide/BgSlide.vue').default,MenuIcon: require('/builds/reifen_gundlach/frontend/components/atoms/MenuIcon/MenuIcon.vue').default,History: require('/builds/reifen_gundlach/frontend/components/organisms/History/History.vue').default,ShoppingCart: require('/builds/reifen_gundlach/frontend/components/organisms/ShoppingCart/ShoppingCart.vue').default,ItemUnderline: require('/builds/reifen_gundlach/frontend/components/atoms/ItemUnderline/ItemUnderline.vue').default,MobileMenu: require('/builds/reifen_gundlach/frontend/components/menu/MobileMenu/MobileMenu.vue').default,Menu: require('/builds/reifen_gundlach/frontend/components/menu/Menu/Menu.vue').default})
